<template>
  <n-timeline>
    <n-timeline-item content="建站时间线" />
    <n-timeline-item
      type="success"
      title="立意"
      content="决定开始折腾服务器"
      time="2018-04-03"
    />
    <n-timeline-item type="success" title="frp" content="达成反代" time="2018-09-01" />
    <n-timeline-item type="success" title="blog" content="折腾hexo" time="2021-04-01" />
    <n-timeline-item
      type="success"
      title="信息"
      content="折腾vue做自定义错误页"
      time="2021-06-11"
    />
  </n-timeline>
</template>
